import { Container } from "./CardsStyle";

export default function InfoCards(props) {
    
    return <Container>
        { props.children }
    </Container>



}